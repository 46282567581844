import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby"
import { useColorMode } from "theme-ui";

import mediaqueries from "../styles/media";
import Image from '../components/Image/Image';
import Headings from "../components/Headings";
import Anchor from '../components/Anchor';
import SubscriptionButton from '../components/SubscriptionButton/SubscriptionButton'
import Section from "../components/Section";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import MainImage from '../../content/authors/avatars/Cameron Nuckols.png'
import NuckolsSignature from '../../content/posts/images/Cameron Nuckols signature.svg'
import NuckolsSignatureWhite from '../../content/posts/images/Cameron Nuckols signature white.svg'

function HomePage({ location, pageContext }) {
  const [colorMode] = useColorMode();

  return (
    <Layout pathname={location.pathname}>
      <SEO pathname={`${location.pathname} - Nucks`} />
        <Section narrow>
        <Container>
          <ProfileImage src={MainImage} width="150px" height="150px" alt="Cameron Nuckols" />
          <Message>
          <Greeting>Hi, I'm Cameron.</Greeting>
          <SubHeading>I'm building a software business—<Anchor href="https://deformity.ai?via=nucks">deformity.ai</Anchor>.</SubHeading>
          <SubHeading>This site is home to my thoughts on entrepreneurship, software, investing, personal finance, self-education, flipping, and more. </SubHeading>
          <SubHeading>Follow along and get the latest spicy content I produce by joining my newsletter.</SubHeading>
          <SubscribeButtonContainer>
            <SubscriptionButton placeholder="Your favorite email" buttonText="Join" />
          </SubscribeButtonContainer>
          <SubHeading>Cheers,</SubHeading>
          {colorMode !== 'dark' ?<Signature src={NuckolsSignature} alt="Cameron Nuckols signature"/> :
          <Signature src={NuckolsSignatureWhite} alt="Cameron Nuckols signature" />}
          </Message>
        </Container>
      </Section>
    </Layout>
  );
}

export default HomePage;

const Signature = styled(Image)`
  width: 200px;
  height: 41px;
`

const SubscribeButtonContainer = styled('div')`
  margin-top: 25px;

  ${mediaqueries.tablet`
    margin-bottom: 20px;
  `}

  ${mediaqueries.phablet`
    margin-bottom: 30px;
  `}
`

const Container = styled('div')`
  display: grid;
  grid-template-columns: 200px auto;

  ${mediaqueries.tablet`
    grid-template-columns: auto;
    margin-top: 80px;
  `}

  ${mediaqueries.phablet`
    margin-top: 80px;
  `}
`

const Message = styled('div')`
  margin-top: 130px;

  ${mediaqueries.tablet`
    margin-top: 25px;
  `}
`

const ProfileImage = styled(Image)`
  border-radius: 50%;
  width: 150px;
`

const InsideLink = styled(Link)`
  transition: ${p => p.theme.colorModeTransition};
  color: ${p => p.theme.colors.accent};

  &:visited {
    color: ${p => p.theme.colors.accent};
    opacity: 0.85;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
}
`

const Greeting = styled(Headings.h1)`
  font-family: inherit;

  ${mediaqueries.tablet`
    margin-bottom: 20px;
  `}

  ${mediaqueries.phablet`
    margin-bottom: 20px;
  `}
`

const SubHeading = styled(Paragraph)`
  margin: 25px 0px 0px 0px;
  max-width: 650px;
  font-size: 19px;

  ${mediaqueries.phablet`
    padding: 0px;
  `}
`
